@font-face {
  font-family: Josefin;
  src: url(./fonts/Josefin_Sans/static/JosefinSans-Regular.ttf);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  display: flex;
  flex-direction: row;
  height: 10vh;
  align-items: center;
  font-size: 7vh;
  font-family: Josefin;
  color: #ffffff;
  margin-bottom: 10vh;
}

.title {
  margin-top: 2vh;
}

.logo {
  width: 10vh;
  margin-right: 30px;
}

.doc {
  width: 90vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 90vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thanks {
  width: 60vw;
  font-size: 9vh;
  font-family: Josefin;
  color: #ffffff;
}

.share {
  width: 60vw;
  font-size: 3.5vh;
  font-family: Josefin;
  color: #ffffff;
  margin-top: 4vh;
  cursor: pointer;
}

.shareSpan {
  margin-right: 10px;
  font-size: 4vh;
}

.foot {
  width: 20vw;
  font-size: 2vh;
  font-family: Josefin;
  color: #ffffff;
  border-top: 1px solid white;
  margin-top: 5vh;
  padding-top: 4vh;
}

.donate-button {
  width: 25vw;
  height: 7vh;
  border-radius: 7vh;
  border: none;
  background-color: #ff8800;
  font-size: 2.5vh;
  font-family: Josefin;
  color: #ffffff;
  padding-top: 1vh;
  margin-top: 5vh;
  cursor: pointer;
}

.submit-button {
  width: 15vw;
  height: 7vh;
  border-radius: 7vh;
  border: none;
  background-color: #ff8800;
  font-size: 2.5vh;
  font-family: Josefin;
  color: #ffffff;
  padding-top: 1vh;
  margin-top: 1vh;
  cursor: pointer;
}

button:disabled{
  background-color: rgb(182, 182, 182);
}

.amount {
  width: 22vw;
  height: 7vh;
  border-radius: 7vh;
  border: none;
  font-size: 2.5vh;
  font-family: Josefin;
  padding-top: 1vh;
  margin: 0vw 1vw 0vw 1vw;
  text-align: center;
}

.devise {
  width: 15vw;
  height: 7vh;
  border-radius: 7vh;
  border: none;
  font-size: 2.5vh;
  font-family: Josefin;
  padding-top: 1vh;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  margin: 0vw 1vw 0vw 1vw;
}

input::placeholder {
  color: #cccccc;
  text-align: center;
}

.pay-button {
  width: 7vh;
  height: 7vh;
  border-radius: 7vh;
  border: none;
  background-color: #ff8800;
  font-size: 2.5vh;
  font-family: Josefin;
  color: #ffffff;
  padding-top: 1vh;
  margin: 0vw 1vw 0vw 1vw;
  cursor: pointer;
}

.fields {
  margin-top: 5vh;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media screen and (max-width: 850px) {
  .header {
    height: 10vh;
    font-size: 7vw;
  }

  .title {
    margin-top: 2vh;
  }

  .logo {
    width: 7vh;
    margin-right: 5px;
  }

  .doc {
    width: 90vw;
    height: 60vh;
  }

  .thanks {
    font-size: 7vw;
  }

  .share {
    width: 60vw;
    cursor: pointer;
    font-size: 5vw;
  }

  .shareSpan {
    font-size: 6vw;
  }

  .foot {
    width: 70vw;
    font-size: 3.5vw;
  }

  .donate-button {
    width: 50vw;
    height: 7vh;
    font-size: 4vw;
  }
  .submit-button {
    width: 50vw;
    height: 7vh;
    font-size: 4vw;
  }
  .amount {
    width: 57vw;
    height: 7vh;
    font-size: 4.5vw;
  }
  .devise {
    width: 48vw;
    height: 7vh;
    font-size: 4.5vw;
    margin: 1vh 3vw 0vw 3vw;
  }
  .pay-button {
    width: 7vh;
    height: 7vh;
    font-size: 4.5vw;
    margin: 1vh 3vw 0vw 3vw;
  }
}

@media screen and (max-width: 400px) {
  .thanks {
    font-size: 9.5vw;
  }

  .donate-button {
    width: 70vw;
    height: 7vh;
    font-size: 6vw;
    margin-top: 15vh;
  }
  .submit-button {
    width: 50vw;
    height: 6vh;
    font-size: 5vw;
    margin-top: 3vh;
  }
  .amount {
    width: 67vw;
    height: 7vh;
    font-size: 5.5vw;
  }
  .devise {
    width: 45vw;
    height: 7vh;
    font-size: 5.5vw;
    margin: 1vh 3vw 0vw 3vw;
  }
  .pay-button {
    width: 7vh;
    height: 7vh;
    font-size: 5.5vw;
    margin: 1vh 3vw 0vw 3vw;
  }

  .share {
    width: 60vw;
    cursor: pointer;
    font-size: 6vw;
  }

  .shareSpan {
    font-size: 7vw;
  }

  .foot {
    width: 70vw;
    font-size: 4.5vw;
  }
}
